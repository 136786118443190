import React, { useEffect, useState } from 'react';

import { Box } from '@core';
import { CountryListSection } from '@containers';
import { CountryListSectionWithoutDataFetch } from '@containers/common/country-list-section/country-list-section';
import { getServers } from '@api/servers';
import { remapServerData } from '@shared/servers-helpers';
import { useGlobalStateContext } from '@context';

const CountryListSectionForBuilder = ({ title, description }) => {
  const [serverData, setServerData] = useState();

  useEffect(() => {
    getServers().then((data) => setServerData(remapServerData(data)));
  }, []);

  return (
    <CountryListSectionWithoutDataFetch
      serverData={serverData}
      title={title}
      description={description}
    />
  );
};

const CountryList = ({ title, description, padding }) => {
  const { isInBuilder } = useGlobalStateContext();

  return (
    <Box {...padding}>
      {isInBuilder ? (
        <CountryListSectionForBuilder title={title} description={description} />
      ) : (
        <CountryListSection title={title} description={description} />
      )}
    </Box>
  );
};

export default CountryList;
